import React, { useState, useEffect} from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { post } from 'aws-amplify/api';
import { getAmount,getReceiptEmail,getOrderId } from './Commons';
import "./App.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  

  async function setInitData() {  
    let amount = getAmount();
    let receipt_email = getReceiptEmail();
    let order_id = getOrderId();

    let path = '/checkout?amount=' + amount + '&receipt_email=' + receipt_email + '&order_id=' + order_id;

    if(amount) {
      try {      
        const restOperation = post({
          apiName: 'payment',
          path: path,        
        });
        const response = await restOperation.response;      
        response.body.json().then((data) => {      
          setClientSecret(data.clientSecret);
        })
        
      } catch (err) {
        console.log(err);
      }
    }    
  }

  useEffect(() => {
    setInitData();
  }, []);


  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
  </div>
  );
}
