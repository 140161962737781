import React from 'react';
import ReactDOM from 'react-dom/client';
import { Authenticator } from '@aws-amplify/ui-react';
import Main from './main';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import './styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <Authenticator>
        {({ signOut, user }) => (
          <Main />
        )}
      </Authenticator>
    </BrowserRouter>
  </>  
);

reportWebVitals();