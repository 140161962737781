import React from "react";
import { Routes, Route } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import App from './App';
import awsExports  from './aws-exports';
import "./App.css";

Amplify.configure({
  ...awsExports,
  Auth: {
    ...awsExports,
    cookieStorage: {
      domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN,
      path: "/",
      expires: 365,
      sameSite: "strict",
      secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== "localhost",
    },
  },
});

export default function Main() {

  return (
    <div className="App">
      <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
  </div>
  );
}

function Thankyou() {    
    return (
        <div>
            <p>Thank you for your payment</p> 
        </div>
    );
}
