export function getAmount() {
    const pathName = window.location.href;
    let paramString = pathName.split('?')[1];
    let queryString = new URLSearchParams(paramString);

    let amount = queryString.get("amount");            
    return amount;
}

export function getReceiptEmail() {
    const pathName = window.location.href;
    let paramString = pathName.split('?')[1];
    let queryString = new URLSearchParams(paramString);

    let receiptEmail = queryString.get("receipt_email");    
    return receiptEmail;
}

export function getOrderId() {
    const pathName = window.location.href;
    let paramString = pathName.split('?')[1];
    let queryString = new URLSearchParams(paramString);

    let orderId = queryString.get("order_id");    
    return orderId;
}

